<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              ref="dxGridRef"
              title="Categories"
              canSelect="none"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadCategories()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  :data-field="item.field"
                  :caption="item.caption"
                  v-for="(item, index) in dataColumns"
                  :key="index"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      hide-footer
      v-model="modalShow"
      centered
      @hide="closeModal()"
      size="lg"
    >
      <validation-observer ref="refCategory" #default="{ invalid }">
        <b-row v-if="controlVisible('name')">
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Category Name"
              vid="v-category_name"
              rules="required"
            >
              <b-form-group :label="controlCaption('name')" label-for="v-name">
                <b-form-input
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  v-model="formData.name"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="controlVisible('description')">
          <b-col>
            <b-form-group
              :label="controlCaption('description')"
              label-for="v-description"
            >
              <b-form-textarea v-model="formData.description" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                size="sm"
                @click="closeModal()"
                variant="danger"
                class="bg-darken-4 mr-1"
              >
                Cancel
              </b-button>
              <b-button
                :disabled="invalid"
                size="sm"
                @click="onSubmitCategory()"
                variant="primary"
                class="bg-darken-4"
              >
                Save
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import categoriesApi from '@api/categories';
import { required } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'CategoryPage',
  components: {},
  data: () => ({
    required,
    modalShow: false,
    dataSource: [],
    selectedData: [],
    totalCount: 0,
    page: 0,
    size: 0,
    fields: [
      { field: 'name', label: 'Name' },
      { field: 'description', label: 'Description' },
    ],

    formData: {
      id: 0,
      name: '',
      description: '',
    },

    dataColumns: [
      { caption: 'Name', field: 'name' },
      { caption: 'Description', field: 'description' },
    ],
    formControls: [
      { caption: 'Name', field: 'name' },
      { caption: 'Description', field: 'description' },
    ],
  }),
  watch: {
    page(v) {
      this.loadCategories();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadCategories();
      }
    },
  },
  mounted() {
    this.loadConfig();
    this.loadCategories();
  },
  methods: {
    // 👉 - loadConfig
    async loadConfig() {
      const response = await fetch('/hbaseConfig.json');
      const data = await response.text();
      const config = JSON.parse(data);
      if (config.category) {
        if (config.category.list) {
          this.dataColumns = [];
          this.dataColumns = config.category.list;
        }
        if (config.category.form) {
          this.formControls = [];
          this.formControls = config.category.form;
        }
      }
    },    
    // 👉 - controlVisible
    controlVisible(name) {
      const ctr = this.formControls.find((c) => c.field === name);
      if (ctr) return true;
      return false;
    },
    controlCaption(name) {
      const ctr = this.formControls.find((c) => c.field === name);
      if (ctr) return ctr.caption;
      return '';
    },

    loadCategories() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      categoriesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
          }

          this.totalCount = meta.page.total;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitCategory() {
      const closeModalLoadCategories = () => {
        this.loadCategories();
        this.closeModal();
      };

      this.$refs.refCategory.validate().then((success) => {
        if (success) {
          if (this.formData.id) {
            categoriesApi
              .update(this.formData)
              .then(({ data }) => {
                closeModalLoadCategories();
              })
              .catch(() => {
                //
              })
              .finally(() => {
                //
              });

            return;
          }

          categoriesApi
            .add(this.formData)
            .then(({ data }) => {
              closeModalLoadCategories();
            })
            .catch(() => {
              //
            })
            .finally(() => {
              //
            });
        }
      });
    },
    onOpenModal(event) {
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    // SECTION Event handlers
    // 👉 - onEdit
    onEdit(data) {
      this.$nextTick(() => {
        this.formData = Object.assign({}, data);
      });
    },
    // 👉 - onDelete
    onDelete(event) {
      categoriesApi
        .delete(event)
        .then(() => {
          this.loadCategories();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    // 👉 - closeModal
    closeModal() {
      this.modalShow = false;
      const defaultForm = {
        id: 0,
        name: '',
        description: '',
        status: 'O',
      };

      this.$nextTick(() => {
        this.formData = Object.assign({}, defaultForm);
      });
    },
    // !SECTION
  },
};
</script>

<style></style>
